// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  cursor: pointer;
  color: #ffffff;
  padding: 0 1.25rem;
  width: 100%;
  font-size: clamp(0.88rem, 0.09vw + 0.86rem, 1rem);
  vertical-align: middle;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  font-stretch: normal;
  font-family: "Lato Bold", sans-serif;
  line-height: 50px;
  letter-spacing: 0.1px;
  text-align: left;
  gap: 16px;
  align-items: center;
  border: 1px solid transparent;
}
.btn:hover {
  background: #5e71db;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/g-button/g-button.component.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACG,cAAA;EACH,kBAAA;EACG,WAAA;EACA,iDAAA;EACH,sBAAA;EACG,aAAA;EACA,uBAAA;EACA,mBAAA;EACH,oBAAA;EACG,oCAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;EACA,SAAA;EACA,mBAAA;EACA,6BAAA;AACJ;AAAC;EACC,mBAAA;AAEF","sourcesContent":[".btn {\r\n\tcursor: pointer;\r\n    color: #ffffff;\r\n\tpadding: 0 1.25rem;\r\n    width: 100%;\r\n    font-size: clamp(0.88rem, 0.09vw + 0.86rem, 1rem);\r\n\tvertical-align: middle;\r\n    display: flex;\r\n    justify-content: center;\r\n    border-radius: 50px;\r\n\tfont-stretch: normal;\r\n    font-family: 'Lato Bold', sans-serif;\r\n    line-height: 50px;\r\n    letter-spacing: 0.1px;\r\n    text-align: left;\r\n    gap: 16px;\r\n    align-items: center;\r\n    border: 1px solid transparent;\r\n\t&:hover {\r\n\t\tbackground: #5e71db;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
