// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.goals {
  background: white;
}
.goals .left-title-goals .goal .goal-title {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
}
.goals .left-title-goals .goal .goal-details {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
}
.goal-details-title {
  font-weight: bold;
}

.goal-detail {
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/goal/goal.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAEY;EACI,eAAA;EACA,aAAA;EACA,8BAAA;AAAhB;AAEY;EACI,eAAA;EACA,aAAA;EACA,8BAAA;AAAhB;AAUA;EACI,iBAAA;AARJ;;AAUA;EACI,YAAA;AAPJ","sourcesContent":[".goals {\r\n    background: white;\r\n    .left-title-goals {\r\n        .goal {\r\n            .goal-title {\r\n                padding: 15px 0;\r\n                display: flex;\r\n                justify-content: space-between;\r\n            }\r\n            .goal-details {\r\n                padding: 15px 0;\r\n                display: flex;\r\n                justify-content: space-between;\r\n                .goal-details-why {\r\n                }\r\n                .goal-areas {\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n\r\n.goal-details-title {\r\n    font-weight: bold;\r\n}\r\n.goal-detail {\r\n    padding: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
