// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm {
  width: 100%;
  display: flex;
  height: 700px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.confirm .title {
  font-size: 1rem;
  margin: 20px;
}
.confirm img {
  width: 60%;
}

.animation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/confirm/confirm.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACJ;AACI;EACI,eAAA;EACA,YAAA;AACR;AAEI;EACI,UAAA;AAAR;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AADJ","sourcesContent":[".confirm {\r\n    width: 100%;\r\n    display: flex;\r\n    height: 700px;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n\r\n    .title {\r\n        font-size: 1rem;\r\n        margin: 20px;\r\n    }\r\n\r\n    img {\r\n        width: 60%;\r\n    }\r\n}\r\n\r\n.animation-wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.img-centered {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
