// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folder-hover:hover {
  background: rgb(167, 193, 249);
  background: linear-gradient(90deg, rgb(167, 193, 249) 0%, rgb(228, 236, 253) 100%);
}

.circle {
  transition: background-color 0.2s ease-in-out;
}
.circle.blue-teal {
  background-color: #5BB7D0;
}

.circle:hover {
  background-color: #e2e8f0;
}

.bordered-tags {
  border-radius: 16px;
  border: 1px solid #2E3D90;
  margin-top: 15px;
  width: 100%;
}

.library-edit-title {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.library-edit-title .in-title {
  border-radius: 20px;
  background: #fff;
}
.library-edit-title > img {
  width: 30px;
  margin-right: 15px;
}

.new-filename-input {
  border-radius: 16px;
  height: 36px;
  border: 1px solid transparent;
  font-size: 0.85rem;
  color: var(--surface-800);
  padding-inline: 0;
}
.new-filename-input:focus {
  outline-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/library/library/components/library-file-actions-dialog/library-file-actions-dialog.component.scss"],"names":[],"mappings":"AACI;EACI,8BAAA;EACA,kFAAA;AAAR;;AAIA;EACI,6CAAA;AADJ;AAGI;EACI,yBAAA;AADR;;AAKA;EACI,yBAAA;AAFJ;;AAKA;EACI,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,WAAA;AAFJ;;AAKA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAGI;EACQ,mBAAA;EACA,gBAAA;AADZ;AAGI;EACI,WAAA;EACA,kBAAA;AADR;;AAKA;EACI,mBAAA;EACA,YAAA;EACA,6BAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;AAFJ;AAGI;EACI,0BAAA;AADR","sourcesContent":[".folder-hover {\r\n    &:hover {\r\n        background: rgb(167, 193, 249);\r\n        background: linear-gradient(90deg, rgba(167, 193, 249, 1) 0%, rgba(228, 236, 253, 1) 100%);\r\n    }\r\n}\r\n\r\n.circle {\r\n    transition: background-color 0.2s ease-in-out;\r\n\r\n    &.blue-teal {\r\n        background-color: #5BB7D0;\r\n    }\r\n}\r\n\r\n.circle:hover {\r\n    background-color: #e2e8f0;\r\n}\r\n\r\n.bordered-tags {\r\n    border-radius: 16px;\r\n    border: 1px solid #2E3D90;\r\n    margin-top: 15px;\r\n    width: 100%;\r\n}\r\n\r\n.library-edit-title {\r\n    display: flex;\r\n    align-items: center;\r\n    font-size: 18px;\r\n    .in-title {\r\n            border-radius: 20px;\r\n            background: #fff;\r\n        }\r\n    > img {\r\n        width: 30px;\r\n        margin-right: 15px;\r\n    }\r\n}\r\n\r\n.new-filename-input {\r\n    border-radius: 16px;\r\n    height: 36px;\r\n    border: 1px solid transparent;\r\n    font-size: 0.85rem;\r\n    color: var(--surface-800);\r\n    padding-inline: 0;\r\n    &:focus {\r\n        outline-color: transparent;\r\n    }\r\n    &:focus-visible {\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
