// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-goal {
  padding: 30px;
  min-height: 500px;
  box-sizing: border-box;
}

.inactive {
  color: lightgray;
  font-weight: bold;
}

.link-main-color {
  font-weight: bold;
}

.btns {
  position: absolute;
  width: 90%;
  bottom: 0;
}

.choose-level {
  margin-top: 80px;
}
.choose-level .choose-level-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 30px 0;
}

.desired-date {
  display: flex;
  justify-content: center;
}

.choose-reason {
  width: 70%;
  margin-left: 20%;
}

.choose-area {
  width: 20%;
  margin-left: 40%;
}

.end {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.end .end-msg {
  margin-top: 30px;
  font-weight: bold;
}

.o-05 {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/goal/create-goal/create-goal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;EACA,SAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;AAAI;EACI,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AAER;;AAEA;EACI,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI,UAAA;EACA,gBAAA;AACJ;;AAEA;EACI,UAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AAAI;EACI,gBAAA;EACA,iBAAA;AAER;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".create-goal {\r\n    padding: 30px;\r\n    min-height: 500px;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.inactive {\r\n    color: lightgray;\r\n    font-weight: bold;\r\n}\r\n\r\n.link-main-color {\r\n    font-weight: bold;\r\n}\r\n\r\n.btns {\r\n    position: absolute;\r\n    width: 90%;\r\n    bottom: 0;\r\n}\r\n\r\n.choose-level {\r\n    margin-top: 80px;\r\n    .choose-level-title {\r\n        width: 100%;\r\n        text-align: center;\r\n        font-weight: bold;\r\n        padding: 30px 0;\r\n    }\r\n}\r\n\r\n.desired-date {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.choose-reason {\r\n    width: 70%;\r\n    margin-left: 20%;\r\n}\r\n\r\n.choose-area {\r\n    width: 20%;\r\n    margin-left: 40%;\r\n}\r\n\r\n.end {\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    .end-msg {\r\n        margin-top: 30px;\r\n        font-weight: bold;\r\n    }\r\n}\r\n\r\n.o-05{\r\n    opacity: 0.5;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
