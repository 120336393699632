// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section {
  background-color: var(--white);
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-top: 20px;
  margin-bottom: 20px;
}
.section .section-content {
  overflow: hidden;
  transition: height 0.3s ease-out;
}

.section-title {
  width: 100%;
  font-size: 25px;
  text-align: center;
  padding: 15px 0;
  box-sizing: border-box;
  border-radius: 28px;
  position: relative;
}
.section-title.gradient-bg {
  background-position: center center;
  border-radius: 20px !important;
}
.section-title .toggle-section {
  position: absolute;
  top: 9px;
  right: 10px;
}
.section-title .toggle-section .section-arrow {
  transform: rotate(180deg);
  overflow: hidden;
  transition-duration: 0.2s;
  transition-property: transform;
  cursor: pointer;
  max-height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/g-accordion/g-accordion.component.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,4BAAA;EACA,6BAAA;EAEA,+BAAA;EAEA,gCAAA;EACA,2CAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAGI;EACI,gBAAA;EACA,gCAAA;AADR;;AAKA;EACI,WAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AAFJ;AAGI;EACI,kCAAA;EACA,8BAAA;AADR;AAGI;EACI,kBAAA;EACA,QAAA;EACA,WAAA;AADR;AAEQ;EACI,yBAAA;EACA,gBAAA;EACA,yBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;AAAZ","sourcesContent":[".section {\r\n    background-color: var(--white);\r\n    border-top-left-radius: 28px;\r\n    border-top-right-radius: 28px;\r\n    // border-bottom-left-radius: 10px;\r\n    border-bottom-left-radius: 28px;\r\n    // border-bottom-right-radius: 10px;\r\n    border-bottom-right-radius: 28px;\r\n    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);\r\n    margin-top: 20px;\r\n    margin-bottom: 20px;\r\n    // padding-bottom: 10px;\r\n    .section-content {\r\n        overflow: hidden;\r\n        transition: height 0.3s ease-out;\r\n    }\r\n}\r\n\r\n.section-title {\r\n    width: 100%;\r\n    font-size: 25px;\r\n    text-align: center;\r\n    padding: 15px 0;\r\n    box-sizing: border-box;\r\n    border-radius: 28px;\r\n    position: relative;\r\n    &.gradient-bg {\r\n        background-position: center center;\r\n        border-radius: 20px!important;\r\n    }\r\n    .toggle-section {\r\n        position: absolute;\r\n        top: 9px;\r\n        right: 10px;\r\n        .section-arrow {\r\n            transform: rotate(180deg);\r\n            overflow: hidden;\r\n            transition-duration: 0.2s;\r\n            transition-property: transform;\r\n            cursor: pointer;\r\n            max-height: 32px;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
