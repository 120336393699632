import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, NavigationStart, Router } from '@angular/router';
import { EventImpl } from '@fullcalendar/core/internal';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { map, skip, switchMap, take } from 'rxjs/operators';
import { LessonStatus, LessonUpdateRequest } from 'src/app/core/models/lesson.model';
import { User, UserRole } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LessonService } from 'src/app/core/services/lesson.service';
import { SubSink } from 'subsink';
import { LessonRatingCalendarDialogComponent } from '../lesson-rating-calendar-dialog/lesson-rating-calendar-dialog.component';
import { ConfirmDialogService } from 'src/app/core/services/confirm-dialog.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { RatingAndReportService } from 'src/app/core/services/rating-and-report.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { Status } from 'src/app/core/models/classroom.model';
import { forkJoin, of } from 'rxjs';
import { LessonBreakdown, LessonUserRating } from 'src/app/core/models/rating.model';
import { Package, PackageDurationHours, PackageType } from 'src/app/core/models/package.model';
import { PackageService } from 'src/app/core/services/package.service';

@Component({
  selector: 'app-lesson-info-calendar-dialog',
  templateUrl: './lesson-info-calendar-dialog.component.html',
  styleUrls: ['./lesson-info-calendar-dialog.component.scss']
})
export class LessonInfoCalendarDialogComponent implements OnInit, OnDestroy {

  role: UserRole = UserRole.NONE;
  UserRoles = UserRole;
  LessonStatuses: LessonStatus = LessonStatus.ARRANGED;
  lessonStatus = LessonStatus;
  classStatus: Status = Status.COMPLETED_TRIAL;
  dialogData: any = {};
  completedLessonHasRating = false;
  subs = new SubSink();
  sideMenuHeight = '100%';
  @ViewChild('op') overlayPanel!: OverlayPanel;
  public readonly packageDurationHours = PackageDurationHours;
  user: User = {} as User;
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private authService: AuthService,
    private router: Router,
    private ratingAndReportService: RatingAndReportService,
    private layoutService: LayoutService,
    public lessonService: LessonService,
    public classroomService: ClassroomService,
    public calendarService: CalendarService,
    public generalService: GeneralService,
    public confirmDialogService: ConfirmDialogService,
    public toastService: ToastService,
    public packageService: PackageService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getLoggedInUser();
    this.role = this.authService.getLoggedInUser().role;
    this.dialogData = this.config.data.dialogData;
    // define dialogData classroom in case is empty from variable
    this.dialogData.classroom = !this.generalService.isNullishObject(this.dialogData.classroom) ? this.dialogData.classroom : this.dialogData.lesson.classroom;
    console.log(this.dialogData);
    this.initConfirmDialogListener();
    this.initRejectDialogListener();
    this.checkCompletedLessonRating();

    this.subs.sink = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Close the dialog reference when navigation starts
        this.ref.close();
      }
    });
    this.subs.sink = this.layoutService.sideMenuHeight.pipe(skip(1)).subscribe((res) => {
      if (res !== 0) {
        this.sideMenuHeight = res + 'px';
      }
    });
    // this.onViewRatingSelected();
  }

  ngOnDestroy(): void {
    this.ref.close();
    this.subs.unsubscribe();
    this.calendarService.setUpdateListener(false);
  }

  onDialogClose(data?: any) {
    this.ref.close(data);
  }

  onRescheduleSelected() {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'lesson': JSON.stringify(this.dialogData.lesson), 'reschedule': true }
    };
    this.router.navigate(['/dashboard/calendar/booking-system'], navigationExtras);
    this.ref.close();
  }

  onGoToClassroomSelected() {
    this.generalService.navigateToLessonDetails(this.dialogData.classroom, this.authService.getUserRole());
    this.ref.close();
  }

  openLessonRatingDialog(ratingMode = 'create') {
    console.log(this.dialogData);
    const mainContentColumnElement = document.getElementById('mainContentColumn');
    console.log(mainContentColumnElement!.getBoundingClientRect().width);

    this.generalService.openDialogWithComponent(this.dialogData.dialogService, LessonRatingCalendarDialogComponent, 
      mainContentColumnElement!.getBoundingClientRect().width,
      {
        lesson: this.dialogData.lesson,
        classroom: this.dialogData.classroom,
        ratingMode: ratingMode,
      }, 'mainContentColumn', (result: any) => {
        console.log(result);
        // if (result && result.action === 'shareWithMultiple') {
        //   this.shareLibraryFiles(result.map.map);
        // }
      }, this.sideMenuHeight);
    this.ref.close();
  }

  /**
   * Changes the status of the lesson based on the provided status string.
   *
   * @param {string} statusString - The status string to change the lesson status to.
   */
  changeLessonStatus(statusString: string) {
    // Get the lesson status from the status string
    const status = this.getLessonStatusFromString(statusString);

    if (!status) {
      // Do nothing if status string not recognized
      return;
    }

    // Check if the classroom is a trial classroom
    const isTrialClassroom = this.classroomService.isTrialClassroom(
      this.dialogData.classroom
    );

    if (status === LessonStatus.COMPLETED && !isTrialClassroom) {
      // Open the lesson rating dialog if the status is completed and it's not a trial classroom
      this.openLessonRatingDialog();
      return;
    }

    if (status === LessonStatus.CANCELED) {
      // Cancel the selected lesson if the status is canceled
      this.cancelLessonSelected();
      return;
    }

    // Update the lesson status
    this.updateLesson(status);
  }

  /**
   * The function `getLessonStatusFromString` takes a string representing a lesson status and returns the
   * corresponding LessonStatus enum value or null if the string does not match any valid status.
   * @param {string} statusString - A string representing the status of a lesson.
   * @returns a value of type LessonStatus or null.
   */
  private getLessonStatusFromString(statusString: string): LessonStatus | null {

    const lowercaseStatus = statusString.toLowerCase();

    switch (lowercaseStatus) {
      case 'completed':
        return this.classroomService.isTrialClassroom(this.dialogData.classroom)
          ? LessonStatus.COMPLETED_TRIAL
          : LessonStatus.COMPLETED;

      case 'rearrange':
        return this.classroomService.isTrialClassroom(this.dialogData.classroom)
          ? LessonStatus.ARRANGED_TRIAL
          : LessonStatus.ARRANGED;

      case 'no show':
        return LessonStatus.NO_SHOW;

      case 'canceled':
        return this.classroomService.isTrialClassroom(this.dialogData.classroom)
          ? LessonStatus.CANCELED_TRIAL
          : LessonStatus.CANCELED;

      default:
        return null;
    }

  }


  /**
   * Updates the lesson with the given status.
   *
   * @param {LessonStatus} status - The new status for the lesson.
   * @return {void} 
   */
  updateLesson(status: LessonStatus) {
    const { id, classroomId, startingDate, duration, isRecccuring } = this.dialogData.lesson;

    // Create the updated lesson object
    const updatedLesson: LessonUpdateRequest = {
      id,
      classroomId,
      status,
      startingDate,
      duration,
      isRecccuring,
      answered: "accepted"
    };

    this.subs.add(
      this.lessonService.update(updatedLesson).subscribe(() => {
        this.hideOverlayPanel();
        this.setUpdateListeners();
        this.onDialogClose({ ...this.dialogData, status });
        this.showToastMessage('success', '', 'Lesson updated.');
      })
    );

    this.updateClassroomStatusBasedOnLessonStatus(status);
  }

  updateClassroomStatus(newStatus: Status) {
    this.subs.add(this.classroomService.changeClassroomStatus(this.dialogData.classroom.id, newStatus).subscribe((response) => {
      console.log(response);
    }));
  }

  cancelLessonSelected() {
    const { classroom, lesson } = this.dialogData;
    const foundPackage = this.packageService.findLessonPackageById(classroom.packages, lesson.packageId) || null;
    const lessonStartDate = lesson.startingDate;

    if (foundPackage) {
      const shouldBypassCancellationPolicy = this.shouldBypassCancellationPolicyBasedOnPackage(foundPackage.type.toLowerCase(), lessonStartDate);

      if (shouldBypassCancellationPolicy) {
        this.prepareAndShowCancelLessonDialog();
      } else if (!shouldBypassCancellationPolicy && !this.isTrialClassroom()) {
        this.prepareAndShowChargeAndCancelLessonDialog(foundPackage);
      }

      console.log(foundPackage);
    } else {
      this.prepareAndShowCancelLessonDialog();
    }
  }

  /**
   * Function to display a confirmation dialog for canceling a lesson.
   */
  private studentLessonCanceledDialog() {
    // Set properties for the confirmation dialog
    this.confirmDialogService.setProperties({
      confirmMessage: 'Are you sure you want to cancel this lesson?',
      acceptBtnLabel: 'Yes',
      rejectBtnLabel: 'No',
      action: 'cancel-lesson',
      confirmIcon: '/assets/icons/clock-dialog-icon.svg',
      showHeader: true,
      dialogType: 'custom',
      headerClass: 'my-custom-header-class'
    });
    // Show the confirmation dialog
    this.confirmDialogService.show();
  }

  /**
   * Function to display a confirmation dialog for charging time.
   */
  chargeTimeSelected() {
    // Set properties for the confirmation dialog
    this.confirmDialogService.setProperties({
      confirmMessage: 'Are you sure you want to charge time?',
      confirmIcon: '/assets/icons/clock-dialog-icon.svg',
      acceptBtnLabel: 'Yes',
      rejectBtnLabel: 'No',
      showHeader: true,
      dialogType: 'custom',
      action: 'charge-lesson',
      headerClass: 'my-custom-header-class'
    });
    // Show the confirmation dialog
    this.confirmDialogService.show();
  }

  /**
   * Function to display a confirmation dialog for charging time based on date and package type.
   */
  ChargeDatePackageType() {
    // Set properties for the confirmation dialog
    this.confirmDialogService.setProperties({
      confirmMessage: 'Are you sure want to charge time?',
      confirmIcon: '/assets/icons/clock-dialog-icon.svg',
      acceptBtnLabel: 'Yes',
      rejectBtnLabel: 'No',
      showHeader: true,
      dialogType: 'custom',
      action: 'charge-lesson',
      headerClass: 'my-custom-header-class'
    });
    // Show the confirmation dialog
    this.confirmDialogService.show();
  }

  /**
   * Function to get the lesson status gradient image.
   * 
   * @returns The lesson status gradient image.
   */
  getLessonStatusGradImage() {
    return this.lessonService.getStatusNameFromStatusString(this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status)).replace(/\s+/g, '-');
  }

  /**
   * Function to handle when student cancel is selected.
   */
  onStudentCancelSelected() {
    const foundPackage = this.packageService.findLessonPackageById(this.dialogData.classroom.packages, this.dialogData.lesson.packageId) || null;
    const lessonStartDate = this.dialogData.lesson.startingDate;
    if (foundPackage) {
      // Checking lesson package cancellation policy
      const shouldBypassCancelationPolicy = this.shouldBypassCancellationPolicyBasedOnPackage(foundPackage!.type.toLowerCase(), lessonStartDate);
      if (shouldBypassCancelationPolicy) {
        this.studentLessonCanceledDialog();
      } else if (!shouldBypassCancelationPolicy && !this.isTrialClassroom()) {
        this.prepareAndShowChargeAndCancelLessonDialogForStudent(foundPackage);
      }
      console.log(foundPackage);
    } else {
      this.studentLessonCanceledDialog();
    }
  }

  /**
   * Function to handle when lesson accepted is clicked.
   */
  onLessonAcceptedClicked() {
    const params = {
      lessonId: this.dialogData.lesson.id
    }
    this.subs.add(this.classroomService.acceptLessonRequest(this.dialogData.lesson.id).subscribe((res) => {
      if (res) {
        this.toastService.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Lesson request accepted.'
        });
        this.calendarService.setToggleOverlayListener(true);
        this.calendarService.setUpdateListener(true);
        this.classroomService.setSelectedClassroomPackagesUpdate(true);
        this.onDialogClose();
      }
    }));
  }

  /**
   * Function to handle when lesson rejected is clicked.
   */
  onLessonRejectedClicked() {
    const params = {
      lessonId: this.dialogData.lesson.id
    }
    this.subs.add(this.classroomService.rejectLessonRequest(this.dialogData.lesson.id).subscribe((res) => {
      if (res) {
        this.toastService.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Lesson request removed.'
        });
        this.calendarService.setToggleOverlayListener(true);
        this.calendarService.setUpdateListener(true);
        this.classroomService.setSelectedClassroomPackagesUpdate(true);
        this.onDialogClose();
      }
    }));
  }

  /**
   * Function to handle when lesson delete is selected.
   */
  onLessonDeleteSelected() {
    this.confirmDialogService.setProperties({
      confirmMessage: `
      Are you sure you want to delete this lesson?`,
      acceptBtnLabel: 'Yes',
      rejectBtnLabel: 'No',
      action: 'delete-lesson',
      confirmIcon: '/assets/icons/clock-dialog-icon.svg',
      showHeader: true,
      dialogType: 'custom',
      headerClass: 'my-custom-header-class'
    });
    this.confirmDialogService.show();
  }

  /**
   * Function to delete the lesson.
   */
  deleteLesson() {
    this.subs.add(this.lessonService.delete(this.dialogData.lesson.id).pipe(take(1)).subscribe(res => {
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Lesson deleted.'
      });
      this.onDialogClose();
      this.calendarService.setUpdateListener(true);
      this.lessonService.setDeleteListener(true);
      this.classroomService.setSelectedClassroomPackagesUpdate(true);
    }));
  }

  /**
   * Function to check if the completed lesson has a rating.
   */
  checkCompletedLessonRating() {
    const selectedlessonId = this.dialogData.lesson.id;
    const selectedlessonStatus = this.dialogData.lesson.status;
    if (selectedlessonStatus.toLowerCase() === LessonStatus.COMPLETED.toLowerCase()) {
      this.subs.sink = this.ratingAndReportService.getLessonBreakDown(selectedlessonId).subscribe((res: any) => {
        
        this.completedLessonHasRating = res.length > 0;
        console.log(res);

      });
    }
  }

  private initConfirmDialogListener() {
    this.subs.add(this.confirmDialogService.acceptSelected$.subscribe(res => {
      this.confirmDialogService.hide();
      console.log(res);
  
      switch (res.action) {
        case 'cancel-lesson':
        case 'cancel-and-charge-lesson-student':
          const statusToSend = this.isTrialClassroom() ? this.lessonStatus.CANCELED_TRIAL.toLowerCase() as LessonStatus : this.lessonStatus.CANCELED.toLowerCase() as LessonStatus;
          this.updateLesson(statusToSend);
          this.calendarService.setUpdateListener(true);
          break;
  
        case 'cancel-and-charge-lesson':
          this.chargeLessonAndUpdateTime();
          break;
  
        case 'charge-lesson':
          const chargeLessonParams = {
            lessonId: this.dialogData.lesson.id,
            answer: true,
          };
          this.subs.add(this.lessonService.chargeLessonTime(chargeLessonParams.lessonId, chargeLessonParams.answer).subscribe((res) => {
            if (res) {
              this.toastService.setShowToastmessage({
                severity: 'success',
                summary: '',
                detail: 'Lesson time charged.'
              });
              this.calendarService.setToggleOverlayListener(true);
              this.calendarService.setUpdateListener(true);
              this.classroomService.setSelectedClassroomPackagesUpdate(true);
              this.dialogData.lesson.chargeTime = true;
              this.onDialogClose();
            }
          }));
          break;
  
        case 'delete-lesson':
          this.deleteLesson();
          break;
      }
  
      this.classroomService.setSelectedClassroomPackagesUpdate(true);
    }));
  }

  /**
   * Initializes the listener for the reject dialog.
   * Handles the action when the reject button is clicked in the dialog.
   */
  private initRejectDialogListener() {
    this.subs.add(this.confirmDialogService.rejectSelected$.subscribe(res => {
      this.confirmDialogService.hide();
      console.log(res);

      switch (res.action) {
        case 'charge-lesson':
          this.handleChargeLesson(false);
          break;
        case 'cancel-and-charge-lesson':
          this.cancelAndChargeLesson(false);
          break;
      }
    }));
  }

  private handleChargeLesson(answer: boolean) {
    const chargeLessonParams = {
      lessonId: this.dialogData.lesson.id,
      answer: answer,
    };

    this.subs.add(this.lessonService.chargeLessonTime(chargeLessonParams.lessonId, chargeLessonParams.answer).subscribe((res) => {
      if (res) {
        this.toastService.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: answer ? 'Lesson time charged.' : 'Lesson time not charged.'
        });

        this.calendarService.setToggleOverlayListener(true);
        this.calendarService.setUpdateListener(true);
        this.onDialogClose();
      }
    }));
  }

  /**
   * Cancels and charges the lesson.
   * 
   * @param answer - The answer to charge the lesson or not.
   */
  private cancelAndChargeLesson(answer: boolean) {
    const chargeLessonParams = {
      lessonId: this.dialogData.lesson.id,
      answer: answer,
    };
    const statusToSend = this.isTrialClassroom() ? this.lessonStatus.CANCELED_TRIAL.toLowerCase() as LessonStatus : this.lessonStatus.CANCELED.toLowerCase() as LessonStatus;
    let lesson: LessonUpdateRequest = {
      id: this.dialogData.lesson.id,
      classroomId: this.dialogData.lesson.classroomId,
      status: statusToSend,
      startingDate: this.dialogData.lesson.startingDate,
      duration: this.dialogData.lesson.duration,
      isRecccuring: this.dialogData.lesson.isRecccuring,
      answered: "accepted"
    };
  
    this.subs.add(this.lessonService.update(lesson).pipe(
      switchMap((res: any) => {
        if (res) {
          console.log(res);
          const userIds = this.dialogData.classroom.classroomStudents.map((el: User) => { return el.aspUserId });
          console.log(userIds);
          this.completedLessonHasRating = res.length > 0;
          return of(true);
        }
        return of(res);
      })
    ).subscribe((res: any) => {
      if (res) {
        this.toastService.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Lesson time not charged.'
        });
        this.calendarService.setToggleOverlayListener(true);
        this.calendarService.setUpdateListener(true);
        this.classroomService.setSelectedClassroomPackagesUpdate(true);
        this.dialogData.lesson.chargeTime = true;
        this.onDialogClose();
      }
    }));
  }

  /**
   * Charges the lesson and updates the time.
   */
  private chargeLessonAndUpdateTime() {
    const chargeLessonParams = {
      lessonId: this.dialogData.lesson.id,
      answer: true,
    }
    const statusToSend = this.isTrialClassroom() ? this.lessonStatus.CANCELED_TRIAL.toLowerCase() as LessonStatus : this.lessonStatus.CANCELED.toLowerCase() as LessonStatus;
    let lesson: LessonUpdateRequest = {
      id: this.dialogData.lesson.id,
      classroomId: this.dialogData.lesson.classroomId,
      status: statusToSend,
      startingDate: this.dialogData.lesson.startingDate,
      duration: this.dialogData.lesson.duration,
      isRecccuring: this.dialogData.lesson.isRecccuring,
      answered: "accepted"
    };
  
    this.subs.add(this.lessonService.update(lesson).pipe(
      switchMap((res: any) => {
        if (res) {
          console.log(res);
          const userIds = this.dialogData.classroom.classroomStudents.map((el: User) => { return el.aspUserId });
          console.log(userIds);
          this.completedLessonHasRating = res.length > 0;
          return this.lessonService.chargeLessonTime(chargeLessonParams.lessonId, chargeLessonParams.answer);
        }
        return of(res);
      })
    ).subscribe((res: any) => {
      if (res) {
        this.toastService.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Lesson time charged.'
        });
        this.calendarService.setToggleOverlayListener(true);
        this.calendarService.setUpdateListener(true);
        this.classroomService.setSelectedClassroomPackagesUpdate(true);
        this.dialogData.lesson.chargeTime = true;
        this.onDialogClose();
      }
    }));
  }

  /**
   * Checks if the classroom is a trial classroom.
   * @returns {boolean} True if the classroom is a trial classroom, false otherwise.
   */
  private isTrialClassroom(): boolean {
    return this.classroomService.isTrialClassroom(this.dialogData.classroom);
  }

  private changeCompletedToArrangedAndDeleteRating() {
    let userRatingIds: number[] = [];
    const selectedlessonId = this.dialogData.lesson.id;

      this.ratingAndReportService.getLessonBreakDown(selectedlessonId).pipe(
        switchMap((res: LessonBreakdown[]) => {
          if (!res) {
            return of(null);
          }
          console.log(res);
          // if (res.length === 0) {
          //   return;
          // }
          const observables = this.dialogData.classroom.classroomStudents.map((user: User) =>
            this.ratingAndReportService.getUserLessonRatings(user.aspUserId!, selectedlessonId)
          );
      
          return forkJoin(observables).pipe(
            map(ratings => ({ ratings, id: res[0].id }))
          );
        }),
        switchMap((res: any) => {
          console.log(res.ratings);
          res.ratings.forEach((rating: any) => {
            console.log(rating);
          });
          const userRatingIds: any[] = [];
      
          for (let rating of res.ratings) {
            userRatingIds.push(rating[0].id);
          }
      
          console.log(res.ratings);
          console.log(userRatingIds);
      
          const observables = res.ratings[0].map((user: User) =>
            this.ratingAndReportService.deleteEndOfLessonRatings(userRatingIds, res.id)
          );
      
          return forkJoin(observables);
        })
      ).subscribe(() => {
        this.changeLessonStatus(this.lessonStatus.REARRANGE);
        // Handle the result of deleteEndOfLessonRatings
      });

    // this.subs.sink = this.ratingAndReportService.getLessonBreakDown(selectedlessonId).pipe(
    //   switchMap((res: any) => {
    //     if (res) {

    //       console.log(res);
    //       const userIds = this.dialogData.classroom.classroomStudents.map((el: User) => { return el.aspUserId });
    //       console.log(userIds);
    //       this.completedLessonHasRating = res.length > 0;
    //       return this.ratingAndReportService.deleteEndOfLessonRatings(userIds,res[0].id );
    //       return of(res);
    //     }
    //     return of(res);
    //   })
    // ).subscribe((res: any) => {
        
    //   console.log(res);

    // });
  }

  /**
   * Prepares and shows the cancel lesson dialog.
   */
  private prepareAndShowCancelLessonDialog() {
    this.confirmDialogService.setProperties({
      confirmMessage: 'Are you sure you want to cancel this lesson?',
      acceptBtnLabel: 'Yes',
      rejectBtnLabel: 'No',
      showHeader: true,
      dialogType: 'custom',
      action: 'cancel-lesson',
      acceptBtnClass: ' ',
      rejectBtnClass: 'cd-dialog-btn-reject',
      headerClass: 'my-custom-header-class'
    });
    this.confirmDialogService.show();
  }

  /**
   * Prepares and shows the charge and cancel lesson dialog.
   * 
   * @param foundPackage The found package for the lesson.
   */
  private prepareAndShowChargeAndCancelLessonDialog(foundPackage: Package | null) {
    this.confirmDialogService.setProperties({
      confirmMessage: foundPackage!.type + ' package ' + this.packageDurationHours[foundPackage!.type] + ' hours cancellation policy applies',
      acceptBtnLabel: 'Accept & Charge',
      rejectBtnLabel: 'Don\'t Charge',
      showHeader: true,
      dialogType: 'custom',
      action: 'cancel-and-charge-lesson',
      headerClass: 'my-custom-header-class',
      acceptBtnClass: 'red-accept-charge',
      rejectBtnClass: 'cd-dialog-btn-accept'
    });
    this.confirmDialogService.show();
  }
  
  /**
   * Prepares and shows the charge and cancel lesson dialog for the student.
   * 
   * @param foundPackage The found package for the lesson.
   */
  private prepareAndShowChargeAndCancelLessonDialogForStudent(foundPackage: Package | null) {
    this.confirmDialogService.setProperties({
      confirmMessage: foundPackage!.type + ' package ' + this.packageDurationHours[foundPackage!.type] + ` hours cancellation policy applies. <br> 
      You will be charged for this lesson. <br><br> Do you want to continue?`,
      acceptBtnLabel: 'Yes',
      rejectBtnLabel: 'No',
      showHeader: true,
      dialogType: 'custom',
      action: 'cancel-and-charge-lesson-student',
      headerClass: 'my-custom-header-class',
      acceptBtnClass: 'red-accept-charge',
      rejectBtnClass: 'cd-dialog-btn-accept'
    });
    this.confirmDialogService.show();
  }

  /**
   * Gets the package duration charge.
   * 
   * @param state The state of the package.
   * @param lessonStartDate The start date of the lesson.
   * @returns Whether to bypass the cancellation policy.
   */
  private shouldBypassCancellationPolicyBasedOnPackage(state: string, lessonStartDate: string) {
    let shouldBypassCancelationPolicy = true;
    switch (state.toLowerCase()) {
      case PackageType.FLEX.toLowerCase():
        shouldBypassCancelationPolicy = this.generalService.isWithinTimeRange(lessonStartDate, this.packageDurationHours[PackageType.FLEX]);
        break;
      case PackageType.REGULAR.toLowerCase():
        shouldBypassCancelationPolicy = this.generalService.isWithinTimeRange(lessonStartDate, this.packageDurationHours[PackageType.REGULAR]);
        break;
      case PackageType.PREMIUM.toLowerCase():
        shouldBypassCancelationPolicy = this.generalService.isWithinTimeRange(lessonStartDate, this.packageDurationHours[PackageType.PREMIUM]);
        break;
    }

    return shouldBypassCancelationPolicy;
  }


  private hideOverlayPanel() {
    if (this.overlayPanel && !this.generalService.isNullishObject(this.overlayPanel)) {
      this.overlayPanel.hide();
    }
  }

  private setUpdateListeners() {
    this.calendarService.setUpdateListener(true);
    this.classroomService.setSelectedClassroomPackagesUpdate(true);
  }

  private showToastMessage(severity: string, summary: string, detail: string) {
    this.toastService.setShowToastmessage({ severity, summary, detail });
  }

  private updateClassroomStatusBasedOnLessonStatus(status: LessonStatus) {
    switch (status) {
      case LessonStatus.COMPLETED_TRIAL:
        this.updateClassroomStatus(Status.COMPLETED_TRIAL);
        break;
      case LessonStatus.CANCELED:
        // Do nothing for now, can add code here if needed
        break;
    }
  }

}
