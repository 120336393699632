// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.classrooms-kinds {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 15px;
  color: var(--main-color);
  cursor: pointer;
}
.classrooms-kinds .level,
.classrooms-kinds .status {
  border: 1px solid var(--main-color);
  text-align: center;
  transition: all 0.3s linear;
  margin-left: 20px;
}
.classrooms-kinds .level {
  width: 33px;
  height: 33px;
  line-height: 33px;
  border-radius: 50%;
}
.classrooms-kinds .status {
  padding: 7px;
  border-radius: 30px;
  width: 79px;
  font-size: 12px;
}

.classrooms-kinds-active,
.classrooms-status-active {
  transition: all 0.3s linear;
  background-color: var(--main-color);
  color: var(--white);
}

.seperator {
  height: 1px;
  background-color: var(--light-purple);
  margin: 30px;
}

.white-button {
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/classroom/classrooms/classrooms-filters-modal/classrooms-filters-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,wBAAA;EACA,eAAA;AACJ;AAAI;;EAEI,mCAAA;EACA,kBAAA;EACA,2BAAA;EACA,iBAAA;AAER;AACI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AACR;AACI;EACI,YAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AACR;;AAGA;;EAEI,2BAAA;EACA,mCAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,WAAA;EAEA,qCAAA;EACA,YAAA;AADJ;;AAIA;EACI,WAAA;AADJ","sourcesContent":[".classrooms-kinds {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-top: 20px;\r\n    font-size: 15px;\r\n    color: var(--main-color);\r\n    cursor: pointer;\r\n    .level,\r\n    .status {\r\n        border: 1px solid var(--main-color);\r\n        text-align: center;\r\n        transition: all 0.3s linear;\r\n        margin-left: 20px;\r\n\r\n    }\r\n    .level {\r\n        width: 33px;\r\n        height: 33px;\r\n        line-height: 33px;\r\n        border-radius: 50%;\r\n    }\r\n    .status {\r\n        padding: 7px;\r\n        border-radius: 30px;\r\n        width: 79px;\r\n        font-size: 12px;\r\n    }\r\n}\r\n\r\n.classrooms-kinds-active,\r\n.classrooms-status-active {\r\n    transition: all 0.3s linear;\r\n    background-color: var(--main-color);\r\n    color: var(--white);\r\n}\r\n\r\n.seperator {\r\n    height: 1px;\r\n    // width: 90%px;\r\n    background-color: var(--light-purple);\r\n    margin: 30px;\r\n}\r\n\r\n.white-button {\r\n    margin: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
