// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-icon-img {
  width: 42px;
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/prime/text-call-to-action-button/text-call-to-action-button.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;AACJ","sourcesContent":[".contact-icon-img {\r\n    width: 42px;\r\n    padding-left: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
