// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-control {
  width: 100%;
  margin-bottom: 3em;
  margin-top: 3em;
  position: relative;
}

.slider {
  position: relative;
  display: inline-block;
  height: 2.5px;
  box-sizing: border-box;
  vertical-align: top;
  background: #ccc;
}

.slider-handle-cont {
  margin-top: -18px;
  position: relative;
}

.slider-handle {
  position: absolute;
  top: -8px;
  height: 15px;
  width: 15px;
  background: #fff;
  cursor: pointer;
  border-radius: 50%;
  background-color: #014666 !important;
  z-index: 10 !important;
}

.slider-handle:hover {
  background: #ddd;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/multi-handle-slider/multi-handle-slider.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,qBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;;AAEE;EACE,iBAAA;EACA,kBAAA;AACJ;;AAEE;EACI,kBAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,oCAAA;EACA,sBAAA;AACN;;AAEE;EACE,gBAAA;AACJ","sourcesContent":[".slider-control {\r\n    width: 100%;\r\n    margin-bottom: 3em;\r\n    margin-top: 3em;\r\n    position: relative;\r\n  }\r\n  \r\n  .slider {\r\n    position: relative; \r\n    display:inline-block;\r\n    height: 2.5px;\r\n    box-sizing: border-box;\r\n    vertical-align: top;\r\n    background: #ccc;\r\n  }\r\n  \r\n  .slider-handle-cont{\r\n    margin-top: -18px;\r\n    position: relative;\r\n  }\r\n  \r\n  .slider-handle {\r\n      position: absolute;\r\n      top:-8px;\r\n      height: 15px;\r\n      width: 15px;\r\n      background: #fff;\r\n      cursor: pointer;\r\n      border-radius: 50%;\r\n      background-color: #014666 !important;\r\n      z-index: 10!important;\r\n  }\r\n  \r\n  .slider-handle:hover {\r\n    background: #ddd;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
