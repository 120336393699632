// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .e-time-cells-wrap {
  display: none !important;
}
:host ::ng-deep .e-schedule-toolbar,
:host ::ng-deep .e-control,
:host ::ng-deep .e-toolbar,
:host ::ng-deep .e-lib,
:host ::ng-deep .e-toolpop,
:host ::ng-deep .e-keyboard {
  box-shadow: none !important;
}

.back-button {
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  position: absolute;
  left: 20px;
  font-weight: bold;
}

.back {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: var(--light-purple);
  color: var(--main-color);
  border-radius: 24px 24px 0 0;
  text-align: center;
  padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/calendar/reschedule/reschedule.component.scss"],"names":[],"mappings":"AACI;EACI,wBAAA;AAAR;AAEI;;;;;;EAMI,2BAAA;AAAR;;AAIA;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,iBAAA;AADJ;;AAIA;EACI,WAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,qCAAA;EACA,wBAAA;EACA,4BAAA;EACA,kBAAA;EACA,aAAA;AADJ","sourcesContent":[":host ::ng-deep {\r\n    .e-time-cells-wrap {\r\n        display: none !important;\r\n    }\r\n    .e-schedule-toolbar,\r\n    .e-control,\r\n    .e-toolbar,\r\n    .e-lib,\r\n    .e-toolpop,\r\n    .e-keyboard {\r\n        box-shadow: none !important;\r\n    }\r\n}\r\n\r\n.back-button {\r\n    padding: 15px;\r\n    display: flex;\r\n    align-items: center;\r\n    cursor: pointer;\r\n    text-align: center;\r\n    position: absolute;\r\n    left: 20px;\r\n    font-weight: bold;\r\n}\r\n\r\n.back {\r\n    width: 100%;\r\n    box-sizing: border-box;\r\n    display: flex;\r\n    align-items: center;\r\n    background-color: var(--light-purple);\r\n    color: var(--main-color);\r\n    border-radius: 24px 24px 0 0;\r\n    text-align: center;\r\n    padding: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
