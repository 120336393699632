// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-overview {
  border-radius: 10px;
  background-color: var(--white);
}
.notifications-overview ::ng-deep .p-calendar.p-calendar-w-btn {
  border: none;
  flex-direction: row-reverse;
}
.notifications-overview ::ng-deep .p-calendar.p-calendar-w-btn .p-inputtext {
  padding: 0.5rem 0.75rem;
}
.notifications-overview ::ng-deep .p-calendar.p-calendar-w-btn .p-inputtext::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--primary-color);
}
.notifications-overview ::ng-deep .p-calendar.p-calendar-w-btn .p-inputtext::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--primary-color);
}
.notifications-overview ::ng-deep .p-calendar.p-calendar-w-btn .p-datepicker-trigger {
  padding: 0;
  justify-content: flex-start;
  width: 1rem;
}
.notifications-overview .block-header {
  background-image: url("/assets/images/notification-overview-header.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}
.notifications-overview .filter-link {
  cursor: pointer;
  opacity: 0.5;
}
.notifications-overview .filter-link.active {
  opacity: 1;
}
.notifications-overview .filter-link:hover {
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/notifications/components/notification-overview/notification-overview.component.scss"],"names":[],"mappings":"AAAA;EAEI,mBAAA;EACA,8BAAA;AAAJ;AAGI;EACI,YAAA;EACA,2BAAA;AADR;AAGQ;EACI,uBAAA;AADZ;AAGY;EACI,yCAAA;EACA,2BAAA;AADhB;AAIY;EACI,mBAAA;EACA,2BAAA;AAFhB;AAOQ;EACI,UAAA;EACA,2BAAA;EACA,WAAA;AALZ;AASI;EACI,wEAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;EACA,mBAAA;AAPR;AAUI;EACI,eAAA;EACA,YAAA;AARR;AAUQ;EACI,UAAA;AARZ;AAWQ;EACI,YAAA;AATZ","sourcesContent":[".notifications-overview {\r\n    // max-height: 770px;\r\n    border-radius: 10px;\r\n    background-color: var(--white);\r\n\r\n\r\n    ::ng-deep .p-calendar.p-calendar-w-btn {\r\n        border: none;\r\n        flex-direction: row-reverse;\r\n\r\n        .p-inputtext {\r\n            padding: 0.5rem 0.75rem;\r\n\r\n            &::placeholder {\r\n                /* Chrome, Firefox, Opera, Safari 10.1+ */\r\n                color: var(--primary-color);\r\n            }\r\n\r\n            &::-ms-input-placeholder {\r\n                /* Microsoft Edge */\r\n                color: var(--primary-color);\r\n            }\r\n\r\n        }\r\n\r\n        .p-datepicker-trigger {\r\n            padding: 0;\r\n            justify-content: flex-start;\r\n            width: 1rem;\r\n        }\r\n    }\r\n\r\n    .block-header {\r\n        background-image: url('/assets/images/notification-overview-header.png');\r\n        background-size: cover;\r\n        background-repeat: no-repeat;\r\n        background-position: center;\r\n        border-radius: 10px;\r\n    }\r\n\r\n    .filter-link {\r\n        cursor: pointer;\r\n        opacity: 0.5;\r\n\r\n        &.active {\r\n            opacity: 1;\r\n        }\r\n\r\n        &:hover {\r\n            opacity: 0.8;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
