import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { Classroom, ClassroomType } from 'src/app/core/models/classroom.model';
import { HomeworkTask } from 'src/app/core/models/homework.model';
import { Folder, FoldersWithFiles, Library, LibraryFile, UploadFilesToFolderRequest } from 'src/app/core/models/library.model';
import { User, UserRole } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LibraryService } from 'src/app/core/services/library.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { MessageService } from 'primeng/api';
import { Observable, forkJoin, of } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { LayoutService } from 'src/app/core/services/layout.service';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  providers: [ConfirmationService],
})
export class LibraryComponent implements OnInit, OnDestroy {

  @ViewChild('addLibrary', { static: true }) public addLibrary: any;
  @ViewChild('leftSide', { static: true }) public leftSide = {} as ElementRef;
  @ViewChild('mainWrapper', { static: true }) public mainWrapper = {} as ElementRef;

  @Input() showHeader = true;
  teacherClassrooms: any;
  sharedByMeLibFolders: any;
  sharedWithMeLibFolders: any;
  classroomFiles: any;
  classroomStudents: any;
  isMyFiles = false;
  showShare: boolean = false;
  StudentIdsToSend: string[] = [];
  folderPath: Folder[] = [this.libraryService.rootFolder];
  fileURL: string | undefined;
  classroomId: number | undefined;
  isDisabled = false;
  isDisabledWholeClass = false;
  isMoveRadioCheked = false;
  moveFileId = 0;
  moveFolderId = 0;
  items!: MenuItem[];
  UserRoles = UserRole;
  public role: string = "";
  public loggedInUser: User = {} as User;
  @Input() inHomework: boolean = false;
  @Input() task: HomeworkTask = {} as HomeworkTask;
  @Input() classroomIdToAddLib: number = 0;
  @Input() selectedClassroom = {} as Classroom;

  private subs = new SubSink()
  term: any;
  showFilters: boolean = false;
  showGroupActions: boolean = false;
  showAddLibrary: boolean = false;
  foldersWithFiles: FoldersWithFiles[] = [];
  @Input() allLibrariesWithFiles: FoldersWithFiles[] = [];
  @Input() title: string = "";
  currentPathLibraries: Library[] = [];
  innerFolders: Folder[] = [];
  libraryToAdd: Library = {} as Library;
  folderName: string = "";
  showInput: boolean = false;
  user: User = {} as User;
  folders: Folder[] = []
  inClassroom: boolean = true;
  showingMine: boolean = true;
  files: LibraryFile[] = [];
  libsSharedWith: FoldersWithFiles[] = [];
  libsSharedBy: FoldersWithFiles[] = [];
  deleteFolderConfirmData = {
    showDialog: false,
    message: 'Delete folder?'
  }
  deleteFileConfirmData = {
    showDialog: false,
    message: 'Delete File?'
  }
  isLoading = true;
  isLarge = false;
  isLeftsideCollapsed: any;
  myLibs: FoldersWithFiles[] = [];
  leftSideHeight = 0;
  tab1checked = true;
  tab2checked = false;
  teacherClassrooms$!: Observable<Classroom[]>;

  constructor(
    private libraryService: LibraryService,
    private classroomService: ClassroomService,
    private generalService: GeneralService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private layoutService: LayoutService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    this.subs.add(this.generalService.deviceKind.pipe().subscribe(res => {
      if (res && res.w768up) {
        this.isLarge = true;
      }
    }));

    // this.generalService.slideNativeElements(true, this.addLibrary.nativeElement);
    this.loggedInUser = this.authService.getLoggedInUser();
    this.role = this.authService.getUserRole();

    this.subs.add(this.libraryService.updateFolderListener.subscribe(res => {
      if (res.folder.folderId! > 0) {
        for (let lib of this.allLibrariesWithFiles) {
          let found: boolean = false;
          for (let file of lib.libraryFiles) {
            if (file.fileId == res.libraryFile.fileId) {
              found = true;
            }
          }
          if (found) {
            lib.folderId = res.folder.folderId!
            lib.name = res.folder.name
            lib.parent = res.folder.parent
          }
        }
      }
    }));

    this.initUpdateListener();
    this.initClassroomUpdateListener();
    this.loadLibFolders();
    this.getTeacherClassRooms();

    // IRAKLIS SOURCE
    this.libraryToAdd = this.libraryService.initializeEmptyLibraryObject()
    this.user = this.authService.getLoggedInUser();
    this.role = this.user.role!
  }

  ngAfterViewInit() {
    this.subs.sink = this.generalService.deviceKind.pipe(
      take(2),
      switchMap(res => {
        console.log(res);
        if (res.is576 || res.is992) {
          return of(0);
        } else {
          return this.layoutService.sideMenuHeight;
        }
      }),
      startWith(0),
    ).subscribe(height => {
      const heightOffset = this.authService.isStudent ? 120 : 115;
      if (height !== 0) {
        this.leftSideHeight = height - heightOffset;
        if (this.mainWrapper) {
          this.mainWrapper.nativeElement.style.height = (height + 80 + 'px');
        }
        if (this.leftSide) {
          this.leftSide.nativeElement.style.maxHeight = (height - 100 + 'px');
        }
        this.cdr.detectChanges();
      } else {
        // this.leftSideHeight = 585;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges() {
    if (this.hasSelectedClassroom) {
      this.onClassRoomSelected(this.selectedClassroom);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.libraryService.setCurrentSelectedClassroomId(0)
    this.libraryService.setCurrentSelectedClassroom({} as Classroom);
    this.libraryService.setMyLibrariesListener(true)
  }

  get hasSelectedClassroom() {
    return (this.selectedClassroom && Object.keys(this.selectedClassroom).length > 0);
  }

  initUpdateListener() {
    this.subs.add(this.libraryService.updateListener.subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.loadLibFolders();
      }
    }));
  }

  initClassroomUpdateListener() {
    this.subs.add(this.libraryService.currentSelectedClassroomId.subscribe(res => {
      if (res != 0) {
        console.log(res);
        this.loadLibraryData(res);
      }
    }));
  }

  loadLibFolders() {
    this.subs.add(this.libraryService.getUserCreatedLibFolders().subscribe(res => {
      this.isLoading = false;
      this.myLibs = res;
      this.libraryService.setLibraryUpdatedListener(true);
    }));
  }

  loadLibraryData(classroomId: number, showLoading = false) {
    if (showLoading) {
      this.isLoading = true;
    }
    this.inClassroom = classroomId !== 0;
    this.libsSharedWith = [];
    this.libsSharedBy = [];
    const requests = [
      this.libraryService.getShareWithMeLibFolders(classroomId),
      this.libraryService.getClassroomFiles(classroomId),
      this.libraryService.getSharedByMeLibFolders(classroomId)
    ];
    this.subs.add(forkJoin(requests).subscribe(([sharedWith, classroomFiles, sharedBy]) => {
      this.isLoading = false;
      if (this.authService.isTeacher) {
        this.libsSharedWith = sharedWith;
        this.libsSharedBy = sharedBy;
      } else {
        this.libsSharedWith = sharedWith;
        this.libsSharedBy = classroomFiles;
      }
    }));
  }

  onShowAddLibrary() {
    this.showAddLibrary = !this.showAddLibrary
    this.libraryToAdd.folder = this.folderPath[this.folderPath.length - 1];
    this.generalService.slideNativeElements(this.showAddLibrary, this.addLibrary.nativeElement);
  }


  getTeacherClassRooms() {
    this.teacherClassrooms$ = this.classroomService.getLMSUserClassrooms(this.loggedInUser.id);
    // this.subs.add(this.classroomService.getLMSUserClassrooms(this.loggedInUser.id).subscribe((response) => {
    //   this.teacherClassrooms = response;
    // }));
    // this.subs.add(this.classroomService.getLMSUserClassrooms(this.loggedInUser.id).subscribe((response) => {
    //   this.teacherClassrooms = response;
    // }));
  }


  // createLibFolder(){
  //   this.libraryService.createFolder().subscribe( (response) => {
  //   });
  // }

  deleteLibFolder(folderId: number) {
    this.subs.add(this.libraryService.deleteFolder(folderId).subscribe((response) => {
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Folder Deleted successfully.'
      });
    }));
  }

  onClassRoomSelected(classroom: Classroom) {
    if (!classroom) {
      return;
    }
    const classroomId = parseInt(classroom.id);
    this.isLoading = true;
    this.isMyFiles = true;
    this.classroomId = classroomId;
    this.classroomStudents = classroom.classroomStudents;

    this.libraryService.setCurrentSelectedClassroomId(parseInt(classroom.id));
    this.libraryService.setCurrentSelectedClassroom(classroom);
    this.libraryService.setMyLibrariesListener(false)
    this.loadLibraryData(classroomId);
  }

  getFileName(path: string) {
    if (path) {
      let n = path.lastIndexOf("/");
      if (n === -1)
        n = path.lastIndexOf("\\");
      let result = path.substring(n + 1);
      return result;
    }
    return path
  }


  // IRAKLIS SOURCE


  getLibFolders() {
    this.isMyFiles = false;
    this.loadLibFolders();
    // this.removeClass()
    this.libraryService.setCurrentSelectedClassroomId(0)
    this.libraryService.setCurrentSelectedClassroom({} as Classroom);
    this.libraryService.setMyLibrariesListener(true)
    // this.libraryService.getUserCreatedLibFolders().subscribe( (response) => {
    //   this.allLibrariesWithFiles = response;
    //   this.classroomFiles = this.allLibrariesWithFiles;
    //   this.getFoldersWithFiles();
    // });
  }

  oncollapseNotesLeftSideChanged(event: any) {
    console.log(event);
    this.isLeftsideCollapsed = event;
  }

  setGroupActions() {
    this.showGroupActions = !this.showGroupActions;
    setTimeout(() => {
      this.libraryService.setGroupActions(this.showGroupActions);
    }, 300);
  }

  onTabChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.id === 'tab-1') {
      // this.libraryService.setshowUploadAction(false);
      // this.libraryService.setshowGroupAction(true);
      
      this.tab1checked = true;
      this.tab2checked = false;
      console.log('Tab 1 is checked');
    } else if (target.id === 'tab-2') {
      // this.libraryService.setshowUploadAction(true);
      // this.libraryService.setshowGroupAction(true);
      console.log('Tab 2 is checked');
      this.tab1checked = false;
      this.tab2checked = true;
    }
  }

}
