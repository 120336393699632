// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://mlt.api.kiddobrains.com",
  leadDynoPrivateKey: '165d71696287208d956a45cffb6d8112b66f0e81',
  isChatEnabled: true,

  firebase: {
    apiKey: "AIzaSyAS3J9uI0k2gtFBSI1daBcG2xbWJrBcCik",
    authDomain: "mylingotrip-d2338.firebaseapp.com",
    databaseURL: "https://mylingotrip-d2338-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mylingotrip-d2338",
    storageBucket: "mylingotrip-d2338.firebasestorage.app",
    messagingSenderId: "618062748166",
    appId: "1:618062748166:web:83fa6d423e3d6913db0187",
    measurementId: "G-QF0X9YTHTL"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
