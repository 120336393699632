// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.classrooms {
  z-index: 1;
  padding: 30px;
  border-radius: 10px;
  background-color: var(--white);
  width: var(--page-width);
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .classrooms {
    padding: 10px;
    width: var(--page-width);
  }
}
.uploader-status {
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.classrooms-header {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}
.classrooms-header .title {
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 113px;
  padding-bottom: 10px;
  cursor: pointer;
}
.classrooms-header .title:hover {
  font-weight: bold;
}

.seperator {
  height: 30px;
  width: 1px;
  background-color: var(--light-purple);
  margin: 0 30px;
}

.classrooms-header-active {
  font-weight: bold;
  border-bottom: 1px solid var(--main-color);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/trial/trial.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,wBAAA;EACA,sBAAA;AACJ;;AACA;EACI;IACI,aAAA;IACA,wBAAA;EAEN;AACF;AACA;EACI,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AACJ;AACI;EACI,wBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,oBAAA;EACA,eAAA;AACR;AACI;EACI,iBAAA;AACR;;AAGA;EACI,YAAA;EACA,UAAA;EACA,qCAAA;EACA,cAAA;AAAJ;;AAGA;EACI,iBAAA;EACA,0CAAA;AAAJ","sourcesContent":[".classrooms {\r\n    z-index: 1;\r\n    padding: 30px;\r\n    border-radius: 10px;\r\n    background-color: var(--white);\r\n    width: var(--page-width);\r\n    box-sizing: border-box;\r\n}\r\n@media screen and (max-width: 768px) {\r\n    .classrooms {\r\n        padding: 10px;\r\n        width: var(--page-width)\r\n    }\r\n}\r\n\r\n.uploader-status {\r\n    height: 700px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.classrooms-header {\r\n    font-size: 15px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: auto;\r\n    // width: var(--page-width);\r\n    .title {\r\n        color: var(--main-color);\r\n        display: flex;\r\n        flex-direction: column;\r\n        align-items: center;\r\n        width: 113px;\r\n        padding-bottom: 10px;\r\n        cursor: pointer;\r\n    }\r\n    .title:hover {\r\n        font-weight: bold;\r\n    }\r\n}\r\n\r\n.seperator {\r\n    height: 30px;\r\n    width: 1px;\r\n    background-color: var(--light-purple);\r\n    margin: 0 30px;\r\n}\r\n\r\n.classrooms-header-active {\r\n    font-weight: bold;\r\n    border-bottom: 1px solid var(--main-color);\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
