// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  border-radius: 4px;
  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 31px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.content {
  padding: 10px;
}

.down-arrow {
  transition: transform 0.2s ease-in-out;
}

.down-arrow.pi-chevron-up {
  transform: rotate(360deg);
}

.circle {
  background: linear-gradient(to bottom right, #9baaff, #152caf);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle i {
  color: white;
  font-size: 16px;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
}

.open {
  max-height: 370px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/g-accordion-item/g-accordion-item.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EAEA,mBAAA;EACA,gBAAA;EACA,2CAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ;;AAOA;EACI,sCAAA;AAJJ;;AAOA;EACI,yBAAA;AAJJ;;AAOA;EACI,8DAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAJJ;;AAOA;EACI,YAAA;EACA,eAAA;AAJJ;;AAOA;EACI,yBAAA;EACA,sCAAA;AAJJ;;AAOA;EACI,aAAA;EACA,gBAAA;EACA,uCAAA;AAJJ;;AAOA;EACI,iBAAA;AAJJ","sourcesContent":[".accordion {\r\n    border-radius: 4px;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 10px;\r\n    cursor: pointer;\r\n\r\n    border-radius: 31px;\r\n    background: #fff;\r\n    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);\r\n}\r\n\r\n.content {\r\n    padding: 10px;\r\n}\r\n\r\n.active {\r\n    // border-color: #007bff;\r\n}\r\n\r\n.down-arrow {\r\n    transition: transform 0.2s ease-in-out;\r\n}\r\n\r\n.down-arrow.pi-chevron-up {\r\n    transform: rotate(360deg);\r\n}\r\n\r\n.circle {\r\n    background: linear-gradient(to bottom right, #9baaff, #152caf);\r\n    width: 24px;\r\n    height: 24px;\r\n    border-radius: 50%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.circle i {\r\n    color: white;\r\n    font-size: 16px;\r\n}\r\n\r\n.rotate {\r\n    transform: rotate(180deg);\r\n    transition: transform 0.3s ease-in-out;\r\n}\r\n\r\n.accordion-content {\r\n    max-height: 0;\r\n    overflow: hidden;\r\n    transition: max-height 0.7s ease-in-out;\r\n}\r\n\r\n.open {\r\n    max-height: 370px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
