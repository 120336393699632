// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.target-img {
  mix-blend-mode: multiply;
}

:host ::ng-deep .block-section {
  height: 100%;
}
:host ::ng-deep .p-dropdown {
  width: 100%;
  background-image: linear-gradient(to bottom, #ffffff, rgba(180, 180, 250, 0.2));
  border: none;
}
:host ::ng-deep .p-dropdown-panel {
  box-shadow: none;
}
:host ::ng-deep .p-dropdown-panel .p-dropdown-items {
  background-image: linear-gradient(to bottom, rgba(180, 180, 250, 0.2), rgba(132, 146, 247, 0.2), rgba(56, 115, 244, 0.2));
  color: #2E3D90;
}
:host ::ng-deep .p-dropdown:not(.p-disabled).p-focus {
  border: 0;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/goal/goal-steps-box/goal-steps-box.component.scss"],"names":[],"mappings":"AAIA;EACI,wBAAA;AAHJ;;AAMI;EACI,YAAA;AAHR;AAKI;EACI,WAAA;EACA,+EAAA;EACA,YAAA;AAHR;AAKI;EACI,gBAAA;AAHR;AAIQ;EACI,yHAAA;EACA,cAAA;AAFZ;AAMQ;EACI,SAAA;EACA,gBAAA;AAJZ","sourcesContent":["\r\n.steps-full-wrapper {\r\n    // height: 80vh;\r\n}\r\n.target-img {\r\n    mix-blend-mode: multiply;\r\n}\r\n:host ::ng-deep {\r\n    .block-section{\r\n        height: 100%;\r\n    }\r\n    .p-dropdown {\r\n        width: 100%;\r\n        background-image: linear-gradient(to bottom, #ffffff, #b4b4fa33);\r\n        border: none;\r\n    }\r\n    .p-dropdown-panel {\r\n        box-shadow: none;\r\n        .p-dropdown-items {\r\n            background-image: linear-gradient(to bottom, #b4b4fa33, #8492f733, #3873f433);\r\n            color: #2E3D90;\r\n        }\r\n    }\r\n    .p-dropdown {\r\n        &:not(.p-disabled).p-focus {\r\n            border: 0;\r\n            box-shadow: none;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
