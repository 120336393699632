// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-image: url('footer.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  color: white;
  padding: 50px;
  font-size: 21px;
  position: relative;
}

.footer-seperator {
  height: 0px;
  background: transparent;
  border: 1px solid #cfd2e3;
}

.social-icon {
  width: 40px;
  margin-top: 30px;
}

.footer-hr {
  margin: 0px 50px 50px 50px;
}

.footer-text {
  padding: 5px 0px;
  text-align: left;
}

.get-in-touch {
  color: white;
}

.row {
  justify-content: space-around;
  text-align: start;
}

.family-btn {
  border-radius: 40px;
  background-color: transparent;
  color: white;
  border-color: white;
}

.link-color:hover {
  color: var(--main-color);
}

.my-hr {
  width: 100%;
  background-color: white;
  height: 1px;
  border-radius: 20px;
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;EACA,2BAAA,EAAA,qBAAA;EACA,4BAAA,EAAA,4BAAA;EACA,sBAAA,EAAA,8DAAA;EACA,oDAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;AACJ;;AAGA;EACI,WAAA;EACA,uBAAA;EACA,yBAAA;AAAJ;;AAMA;EACI,WAAA;EACA,gBAAA;AAHJ;;AAMA;EACI,0BAAA;AAHJ;;AAMA;EACI,gBAAA;EACA,gBAAA;AAHJ;;AAMA;EACI,YAAA;AAHJ;;AAMA;EACI,6BAAA;EACA,iBAAA;AAHJ;;AAMA;EACI,mBAAA;EACA,6BAAA;EACA,YAAA;EACA,mBAAA;AAHJ;;AAMA;EACI,wBAAA;AAHJ;;AAMA;EACI,WAAA;EACA,uBAAA;EACA,WAAA;EACA,mBAAA;EACA,cAAA;AAHJ","sourcesContent":[".footer {\r\n    background-image: url(\"../../../assets/icons/footer.png\");\r\n    background-position: center; /* Center the image */\r\n    background-repeat: no-repeat; /* Do not repeat the image */\r\n    background-size: cover; /* Resize the background image to cover the entire container */\r\n    font-family: \"Raleway\", Arial, Helvetica, sans-serif;\r\n    color: white;\r\n    padding: 50px;\r\n    font-size: 21px;\r\n    position: relative;\r\n    // margin-top: 700px;\r\n}\r\n\r\n.footer-seperator {\r\n    height: 0px;\r\n    background: transparent;\r\n    border: 1px solid #cfd2e3;\r\n}\r\n\r\n.social-media {\r\n}\r\n\r\n.social-icon {\r\n    width: 40px;\r\n    margin-top: 30px;\r\n}\r\n\r\n.footer-hr {\r\n    margin: 0px 50px 50px 50px;\r\n}\r\n\r\n.footer-text {\r\n    padding: 5px 0px;\r\n    text-align: left;\r\n}\r\n\r\n.get-in-touch {\r\n    color: white;\r\n}\r\n\r\n.row {\r\n    justify-content: space-around;\r\n    text-align: start;\r\n}\r\n\r\n.family-btn{\r\n    border-radius: 40px;\r\n    background-color: transparent;\r\n    color: white;\r\n    border-color: white;\r\n}\r\n\r\n.link-color:hover{\r\n    color: var(--main-color)\r\n}\r\n\r\n.my-hr {\r\n    width: 100%;\r\n    background-color: white;\r\n    height:1px;\r\n    border-radius: 20px;\r\n    margin: 10px 0;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
