// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lottie-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 200px;
}

.not-found-wrapper {
  max-width: 600px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qBAAA;EACA,uBAAA;EACA,aAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".lottie-wrapper{\r\n    display: flex;\r\n    align-content: center;\r\n    justify-content: center;\r\n    height: 200px;\r\n}\r\n\r\n.not-found-wrapper {\r\n    max-width: 600px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
