// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-upload-details {
  display: flex;
  flex-direction: column;
}
.library-upload-details .library-upload-details-left > div,
.library-upload-details .library-upload-details-right > div {
  margin-top: 15px;
}
.library-upload-details .library-upload-details-right .library-upload-checkboxes {
  display: flex;
}
.library-upload-details .library-upload-details-right .library-upload-checkboxes .library-upload-checkbox {
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
}
.library-upload-details .library-upload-details-right .library-upload-checkboxes .library-upload-checkbox > div {
  margin-left: 10px;
}

.e-checkbox-wrapper .e-label {
  color: var(--primary-color);
}

.e-checkbox-wrapper.e-primary .e-frame {
  border-radius: 50px;
  border-color: #5BB7D0;
}
.e-checkbox-wrapper.e-primary .e-frame.e-check {
  background-color: #5BB7D0;
}

:host ::ng-deep p-checkbox {
  width: 5.4rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/upload-files/choose-tags/choose-tags.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AAEQ;;EACI,gBAAA;AACZ;AAIQ;EACI,aAAA;AAFZ;AAGY;EACI,kBAAA;EACA,mBAAA;EACA,aAAA;AADhB;AAEgB;EACI,iBAAA;AAApB;;AAMA;EAEI,2BAAA;AAJJ;;AAOA;EACI,mBAAA;EACA,qBAAA;AAJJ;AAKI;EACI,yBAAA;AAHR;;AASI;EACI,aAAA;AANR","sourcesContent":[".library-upload-details {\r\n    display: flex;\r\n    flex-direction: column;\r\n    .library-upload-details-left,\r\n    .library-upload-details-right {\r\n        > div {\r\n            margin-top: 15px;\r\n        }\r\n    }\r\n    .library-upload-details-right {\r\n        // margin-left: 10px;\r\n        .library-upload-checkboxes {\r\n            display: flex;\r\n            .library-upload-checkbox {\r\n                margin-right: 20px;\r\n                margin-bottom: 10px;\r\n                display: flex;\r\n                > div {\r\n                    margin-left: 10px;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n.e-checkbox-wrapper .e-label {\r\n    \r\n    color: var(--primary-color);\r\n}\r\n\r\n.e-checkbox-wrapper.e-primary .e-frame {\r\n    border-radius: 50px;\r\n    border-color: #5BB7D0;\r\n    &.e-check {\r\n        background-color: #5BB7D0;\r\n    }\r\n}\r\n\r\n:host ::ng-deep { \r\n\r\n    p-checkbox {\r\n        width: 5.4rem;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
