// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.classroom-users-list {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.stick-search {
  position: sticky;
  top: 0;
  z-index: 1;
}

.list {
  width: 300px;
  font-size: 16px;
}

.send-classroom-title {
  display: flex;
  align-items: center;
}
.send-classroom-title .title {
  margin-left: 10px;
  font-weight: bold;
  width: 70%;
}

.e-checkbox-wrapper.e-primary .e-frame {
  border-radius: 50px;
  border-color: #5BB7D0;
}
.e-checkbox-wrapper.e-primary .e-frame.e-check {
  background-color: #5BB7D0;
}

.single-classroom-wrapper {
  border-bottom: 1px solid rgba(46, 61, 144, 0.23);
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/classrooms-check-list/classrooms-check-list.component.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,MAAA;EAEA,UAAA;AAFJ;;AAKA;EACI,YAAA;EACA,eAAA;AAFJ;;AAKA;EACI,aAAA;EACA,mBAAA;AAFJ;AAGI;EACI,iBAAA;EACA,iBAAA;EACA,UAAA;AADR;;AAKA;EACI,mBAAA;EACA,qBAAA;AAFJ;AAGI;EACI,yBAAA;AADR;;AAKA;EACI,gDAAA;EACA,oBAAA;AAFJ","sourcesContent":["// @import '../../../assets/styles/my-radio.scss';\r\n\r\n.classroom-users-list {\r\n    margin-top: 15px;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.stick-search {\r\n    position: sticky;\r\n    top: 0;\r\n    // background-color: white;\r\n    z-index: 1;\r\n}\r\n\r\n.list {\r\n    width: 300px;\r\n    font-size: 16px;\r\n}\r\n\r\n.send-classroom-title {\r\n    display: flex;\r\n    align-items: center;\r\n    .title{\r\n        margin-left: 10px;\r\n        font-weight: bold;\r\n        width: 70%;\r\n    }\r\n}\r\n\r\n.e-checkbox-wrapper.e-primary .e-frame {\r\n    border-radius: 50px;\r\n    border-color: #5BB7D0;\r\n    &.e-check {\r\n        background-color: #5BB7D0;\r\n    }\r\n}\r\n\r\n.single-classroom-wrapper {\r\n    border-bottom: 1px solid rgb(46 61 144 / 23%);\r\n    padding-bottom: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
