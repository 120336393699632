// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folder-options-icon {
  right: -15px;
  transform: rotate(90deg);
}

.folder-name-tag {
  width: auto;
  height: 18.68px;
  border-radius: 9.34px;
  background: #1a266c;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/library/library/components/single-library-folder-item/single-library-folder-item.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,wBAAA;AACJ;;AAEA;EACI,WAAA;EACA,eAAA;EACA,qBAAA;EACA,mBAAA;EACA,WAAA;AACJ","sourcesContent":[".folder-options-icon {\r\n    right: -15px;\r\n    transform: rotate(90deg);\r\n}\r\n\r\n.folder-name-tag {\r\n    width: auto;\r\n    height: 18.68px;\r\n    border-radius: 9.34px;\r\n    background: #1a266c;\r\n    color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
