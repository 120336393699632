// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circles {
  display: flex;
  justify-content: space-between;
}
.circles .circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: var(--light-purple);
  border: 1px solid var(--light-purple);
  text-align: center;
  border-radius: 50%;
  position: relative;
}
.circles .circle .line {
  position: absolute;
  left: 42px;
  top: 21px;
  height: 1px;
  border-top: 1px solid lightgray;
  width: 42px;
}

.hoverit {
  cursor: pointer;
}

.hoverit:hover {
  background: var(--light-purple) !important;
  color: white !important;
}

.hoverit-main {
  cursor: pointer;
}

.hoverit-main:hover {
  background: var(--main-color) !important;
  color: white !important;
}

.extra-text {
  color: var(--light-purple);
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/circle-line/circle-line.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,+BAAA;EACA,qCAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;AAER;AADQ;EACI,kBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,+BAAA;EACA,WAAA;AAGZ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,0CAAA;EACA,uBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,wCAAA;EACA,uBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,mBAAA;AACJ","sourcesContent":[".circles {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    .circle {\r\n        width: 40px;\r\n        height: 40px;\r\n        line-height: 40px;\r\n        background: var(--light-purple);\r\n        border: 1px solid var(--light-purple);\r\n        text-align: center;\r\n        border-radius: 50%;\r\n        position: relative;\r\n        .line {\r\n            position: absolute;\r\n            left: 42px;\r\n            top: 21px;\r\n            height: 1px;\r\n            border-top: 1px solid lightgray;\r\n            width: 42px;\r\n        }\r\n    }\r\n}\r\n\r\n.hoverit {\r\n    cursor: pointer;\r\n}\r\n\r\n.hoverit:hover {\r\n    background: var(--light-purple) !important;\r\n    color: white !important;\r\n}\r\n\r\n.hoverit-main {\r\n    cursor: pointer;\r\n}\r\n\r\n.hoverit-main:hover {\r\n    background: var(--main-color) !important;\r\n    color: white !important;\r\n}\r\n\r\n.extra-text {\r\n    color: var(--light-purple);\r\n    white-space: nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
