// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-line-bottom {
  border-bottom: 0.5px solid rgba(94, 113, 219, 0.5);
}

.title-text {
  letter-spacing: 0.01em;
  text-align: center;
  color: #000b4a;
}

.text-blue-dark-color {
  color: #000b4a;
}

.level-icon-position {
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}

.divider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  /* Set the width of the divider */
  background-color: rgba(94, 113, 219, 0.5);
  /* Set the color of the divider */
  left: 50%;
  /* Position the divider in the middle of the container */
  transform: translateX(-50%);
  /* Center the divider horizontally */
}`, "",{"version":3,"sources":["webpack://./src/app/shared/classroom/teacher-classroom-mini-card/teacher-classroom-mini-card.component.scss"],"names":[],"mappings":"AAEC;EACI,kDAHW;AAEhB;;AAIC;EACI,sBAAA;EACA,kBAAA;EACA,cAAA;AADL;;AAIC;EACI,cAAA;AADL;;AAIC;EACI,SAAA;EACA,2BAAA;EACA,SAAA;AADL;;AAIC;EACI,kBAAA;EACA,MAAA;EACA,SAAA;EACA,UAAA;EACA,iCAAA;EACA,yCAAA;EACA,iCAAA;EACA,SAAA;EACA,wDAAA;EACA,2BAAA;EACA,oCAAA;AADL","sourcesContent":[" $border-style: 0.5px solid rgba(#5e71db, 0.5);\r\n\r\n .border-line-bottom {\r\n     border-bottom: $border-style;\r\n }\r\n\r\n .title-text {\r\n     letter-spacing: 0.01em;\r\n     text-align: center;\r\n     color: #000b4a;\r\n }\r\n\r\n .text-blue-dark-color {\r\n     color: #000b4a;\r\n }\r\n\r\n .level-icon-position {\r\n     left: 50%;\r\n     transform: translateX(-50%);\r\n     top: 100%;\r\n }\r\n\r\n .divider {\r\n     position: absolute;\r\n     top: 0;\r\n     bottom: 0;\r\n     width: 1px;\r\n     /* Set the width of the divider */\r\n     background-color: rgba(#5e71db, 0.5);\r\n     /* Set the color of the divider */\r\n     left: 50%;\r\n     /* Position the divider in the middle of the container */\r\n     transform: translateX(-50%);\r\n     /* Center the divider horizontally */\r\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
