// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-group {
  display: flex;
  justify-content: start;
  align-items: center;
}

.type-button {
  padding: 8px 0px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  position: relative;
}
.type-button.active::after {
  content: "";
  border-bottom: 3px solid #fff;
  width: 10px;
  content: "";
  border-bottom: 1px solid #fff;
  width: 100%;
  position: absolute;
  bottom: 3px;
  left: 0;
}

.type-button-active {
  color: var(--primary-color);
}

.line {
  width: 1px;
  height: 20px;
  background-color: #fff;
  opacity: 0.5;
  margin: 0 10px;
}

.block-gradient-reverse .button-group .type-button {
  color: var(--primary-color);
}
.block-gradient-reverse .button-group .line {
  background-color: #3345A7;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/lesson/lesson-schedule-box/lesson-schedule-box.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,6BAAA;EACA,6BAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AACJ;AAEQ;EACI,WAAA;EACA,6BAAA;EACA,WAAA;EACA,WAAA;EACA,6BAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,OAAA;AAAZ;;AAOA;EACI,2BAAA;AAJJ;;AAOA;EACI,UAAA;EACA,YAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;AAJJ;;AAUY;EACI,2BAAA;AAPhB;AAUY;EACI,yBAAA;AARhB","sourcesContent":[".button-group {\r\n    display: flex;\r\n    justify-content: start;\r\n    align-items: center;\r\n}\r\n\r\n.type-button {\r\n    padding: 8px 0px;\r\n    background-color: transparent;\r\n    border: 1px solid transparent;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    color: white;\r\n    position: relative;\r\n\r\n    &.active {\r\n        &::after {\r\n            content: '';\r\n            border-bottom: 3px solid #fff;\r\n            width: 10px;\r\n            content: \"\";\r\n            border-bottom: 1px solid #fff;\r\n            width: 100%;\r\n            position: absolute;\r\n            bottom: 3px;\r\n            left: 0;\r\n        }\r\n    }\r\n}\r\n\r\nbutton:hover {}\r\n\r\n.type-button-active {\r\n    color: var(--primary-color);\r\n}\r\n\r\n.line {\r\n    width: 1px;\r\n    height: 20px;\r\n    background-color: #fff;\r\n    opacity: 0.5;\r\n    margin: 0 10px;\r\n}\r\n\r\n.block-gradient-reverse {\r\n\r\n        .button-group {\r\n            .type-button {\r\n                color: var(--primary-color);\r\n            }\r\n        \r\n            .line {\r\n                background-color: #3345A7;\r\n            }\r\n        }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
